import './src/css/bootstrap.scss'

export const onRouteUpdate = ({ location, prevLocation }) => {
  // console.log('new pathname', location.pathname)
  // console.log('old pathname', prevLocation ? prevLocation.pathname : null)

  window.dataLayer = window.dataLayer || []
  if (location.pathname !== (prevLocation ? prevLocation.pathname : null)) {
    window.dataLayer.push({
      event: 'pageview',
      page: location.pathname,
    })
  }
}
